<template>
    <div>
        <v-card outlined flat>
            <v-container fluid>
                <v-row>
                    <v-col cols="12" md="3">
                        <v-text-field v-model="body.name" label="إسم المجموعة" filled outlined no-data-text="لا توجد بيانات" hide-details="auto" />
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field v-model="body.description" label="وصف المجموعة" filled outlined no-data-text="لا توجد بيانات" hide-details="auto" />
                    </v-col>
                    <v-col cols="12" md="3" v-if="$store.state.user.userType.id == $global.state.roleType.admin || $store.state.user.roles.find(x => x.key == `${$route.path.slice(1)}-delete`) ">
                        <v-btn @click="submit()" :disabled="body.roleId.length == 0 || body.name == ''" :loading="addItemLoading" x-large :color="actionType == 'add' ? 'primary' : 'info' " class="btn">
                            {{ actionType == 'add' ? 'حفظ' : 'تحديث' }}
                        </v-btn>
                    </v-col>
                    <v-col cols="12">
                        
                        <v-autocomplete v-model="body.roleId" chips multiple :items="roles" item-text="arName" item-value="id" label="الصلاحيات" filled outlined no-data-text="لا توجد بيانات" hide-details="auto" :key="body.name" item-selector="true">
                            <template v-slot:selection="data">
                                <v-chip :id="getColor(data.item)" label v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)">
                                    {{data.item.arName}}
                                </v-chip>
                            </template>
                            <template v-slot:item="data">
                                <!-- <template v-if="typeof data.item != 'object'">
                                    <v-list-item-content>
                                        {{ data.item }}
                                    </v-list-item-content>
                                </template> -->
                                <template >
                                    <v-list-item-content>
                                        <v-list-item-title :class="getColor(data.item)+'--text'">
                                            <h3>
                                                {{ data.item.arName }}
                                            </h3>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                        <v-btn @click="selectAll()" color="primary" class="btn mt-1">
                            <h3>
                                {{ selectAllChecked == false ? 'تحديد الكل' : 'الغاء التحديد للكل' }}
                                
                            </h3>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <br />
        <v-card flat outlined>
            <v-data-table :loading="loading" :items="items" :headers="headers" hide-default-footer no-data-text="لا توجد بيانات" loading-text="جاري تحميل البيانات...">
    
                <template v-slot:item.roles="{item}">
                    <v-chip :id="getColor(role)" small class="rounded-tl-xl rounded-br-xl ma-1" v-for="(role,index) in item.roles" :key="index" label>
                        {{role.arName}}
                    </v-chip>
                </template>
    
                <template v-slot:item.actions="{item, index}">
                    <div style="min-width: 120px">
                        <v-tooltip bottom="" v-if="$store.state.user.userType.id == $global.state.roleType.admin || $store.state.user.roles.find(x => x.key == `${$route.path.slice(1)}-delete`) ">
                            <template v-slot:activator="{ on }">
                                <v-btn @click.stop="deleteItem(item.id, index)" v-on="on" icon depressed class="ml-3" x-small>
                                    <v-icon color="error"> fi fi-rr-trash </v-icon>
                                </v-btn>
                            </template>
                            <span>حذف</span>
                        </v-tooltip>
    
                        <v-tooltip bottom="" v-if="$store.state.user.userType.id == $global.state.roleType.admin || $store.state.user.roles.find(x => x.key == `${$route.path.slice(1)}-update`)">
                            <template v-slot:activator="{ on }">
                                <v-btn @click.stop="fillEditField(item)" v-on="on" icon color="info" depressed class="ml-3" x-small>
                                    <v-icon> fi fi-rr-edit </v-icon>
                                </v-btn>
                            </template>
                            <span>تعديل</span>
                        </v-tooltip>
    
                    </div>
                </template>
    
            </v-data-table>
        </v-card>
    </div>
    </template>
    
    <script>
    export default {
    
        data() {
            return {
                items: [],
                roles: [],
                body: {},
                loading: false,
                addItemLoading: false,
                actionType: 'add',
                currentItemId: '',
                selectAllChecked: false,
                headers: [{
                        text: 'إسم المجموعة',
                        value: 'name',
                        width: '200px'
                    },
                    {
                        text: 'وصف',
                        value: 'description',
                        width: '150px'
                    },
                    {
                        text: 'الصلاحيات',
                        value: 'roles'
                    },
    
                    {
                        text: '',
                        value: 'actions'
                    }
                ]
    
            }
        },
    
        created() {
            this.getItems()
            this.getRoles()
            
            this.reset()
            
        },
    
        methods: {
            reset() {
                this.body = {
                    "name": "",
                    "description": "",
                    "roleId": []
                }
                this.actionType = 'add'
                this.currentItemId = ''
            },
    
            submit() {
                if (this.actionType == 'add') {
                    this.addItem()
                } else {
                    this.editItem()
                }
            },
    
            async getItems() {
                this.loading = true
                try {
                    let res = await this.$http.get(`AuthGroup`)
                    this.items = res.data.result
                } catch (err) {
                    console.log(err)
                } finally {
                    this.loading = false
                }
            },
    
            async getRoles() {
                this.loading = true
                try {
                    let res = await this.$http.get(`Role`)
                    this.roles = res.data.result
                    
                } catch (err) {
                    console.log(err)
                } finally {
                    this.loading = false
                }
            },
    
            selectAll() {
                if (this.selectAllChecked) {
                    // Clear all selections
                    this.body.roleId = [];
                } else {
                    // Select all options except the "Select All" item
                    this.body.roleId = this.roles
                        .filter(role => role.id !== "select-all")
                        .map(role => role.id);
                }
                this.selectAllChecked = !this.selectAllChecked;
                this.$nextTick(() => {
                    this.$refs.rolesAutocomplete.hideMenu();
                });
            },
    
            async addItem() {
                this.addItemLoading = true
                try {
                    let res = await this.$http.post(`AuthGroup`, this.body)
                    this.getItems()
                    this.reset()
                    console.log(res)
                } catch (err) {
                    console.log(err)
                } finally {
                    this.addItemLoading = false
                }
            },
    
            async editItem() {
                this.addItemLoading = true
                try {
                    let res = await this.$http.put(`AuthGroup/${this.currentItemId}`, this.body)
                    this.getItems()
                    this.reset()
                } catch (err) {
                    console.log(err)
                } finally {
                    this.addItemLoading = false
                }
            },
    
            getColor(item) {
                if (item.key.includes('delete')) {
                    return 'error'
                } else if (item.key.includes('update')) {
                    return 'orange'
                } else if (item.key.includes('add')) {
                    return 'teal'
                } else if (item.key.includes('get')) {
                    return 'primary'
                } else {
                    return 'grey'
                }
            },
    
            remove(item) {
                const index = this.body.roleId.indexOf(item.id)
                console.log(index)
                if (index >= 0) this.body.roleId.splice(index, 1)
                // this.body.roleId.splice(index, 1)
            },
    
            fillEditField(item) {
                this.reset()
                this.actionType = 'edit'
                this.currentItemId = item.id
                let currentItem = {
                    "name": item.name,
                    "description": item.description,
                    "roleId": []
                }
                for (let i = 0; i < item.roles.length; i++) {
                    currentItem.roleId.push(item.roles[i].id)
                }
                this.body = currentItem
            },
    
            async deleteItem(id, i) {
                this.$genericService.swalAlertConfirm({}).then((result) => {
                    if (result.isConfirmed) {
                        this.$genericService
                            .delete(`AuthGroup/${id}`)
                            .then((res) => {
                                this.$store.dispatch("toastification/setToast", {
                                    message: `تم الحذف بنجاح`,
                                    type: "success",
                                });
                                this.items.splice(i, 1);
                            })
                            .catch((err) => {
                                this.$store.dispatch("toastification/setToast", {
                                    message: `${err.data.message}`,
                                    type: "error",
                                });
                            });
                    }
                });
            },
        },
    }
    </script>
    
    <style scoped>
    #error {
        background: rgba(173, 20, 86, 0.25);
        color: rgb(173, 20, 87);
    }
    
    #orange {
        background: rgba(255, 151, 25, 0.25);
        color: rgb(255, 152, 0);
    }
    
    #teal {
        background: rgba(0, 150, 135, 0.25);
        color: rgb(0, 150, 136);
    }
    
    #primary {
        background: rgba(68, 96, 163, 0.25);
        color: rgb(68, 96, 163);
    }
    
    #grey {
        background: rgba(224, 224, 224, 0.436);
        color: rgb(19, 19, 19);
    }
    </style>
    